import { ReactNode } from "react";

interface ContentProps {
  children: ReactNode;
}
const Content: React.FC<ContentProps> = ({ children }) => {
  return (
    <div className="px-5 md:ml-64 flex h-screen flex-col bg-white dark:bg-gray-900 text-black dark:text-white">
      {children}
    </div>
  );
};

export default Content;
