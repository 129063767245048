import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import EventAPI from "../services/EventAPI";
import { type getStores } from "../types";

const SITE_REFRESH_DURATION = 60 * 60 * 1000; // 1 hour in ms

export const useAllowedSites = () => {
  const [sites, setSites] = useState<getStores[]>([]);
  const [loadingSites, setLoadingSites] = useState(true);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchSites = async () => {
      const token = await getAccessTokenSilently();
      const sites = await EventAPI.allowedSites(token);
      setSites(sites);
    };

    fetchSites()
      .then(() => {
        setLoadingSites(false);
      })
      .catch((err) => console.error(err));

    const interval = setInterval(fetchSites, SITE_REFRESH_DURATION);
    return () => clearInterval(interval);
  }, [isAuthenticated, getAccessTokenSilently]);

  return { sites, loadingSites };
};
