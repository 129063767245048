import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import EventAPI from "../services/EventAPI";

const ACCESS_REFRESH_DURATION = 60 * 60 * 1000; // 1 hour in ms

export const useSiteContentAccess = (selectedSiteId: string | null) => {
  const [loadingAccess, setLoadingAccess] = useState(true);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getSiteContentAccess = async () => {
      if (!isAuthenticated || !selectedSiteId) return;

      const token = await getAccessTokenSilently();
      await EventAPI.getContentAccess(selectedSiteId, token);
    };

    getSiteContentAccess()
      .then(() => {
        setLoadingAccess(false);
      })
      .catch((err) => console.error(err));

    const interval = setInterval(getSiteContentAccess, ACCESS_REFRESH_DURATION);
    return () => clearInterval(interval);
  }, [isAuthenticated, selectedSiteId, getAccessTokenSilently]);

  return { loadingAccess };
};
