import { Button, Spinner, Textarea } from "@material-tailwind/react";
import { useState } from "react";
import { type MonitorEvent } from "../../types";
import EventAPI from "../../services/EventAPI";
import { useAuth0 } from "@auth0/auth0-react";
import { useAlert } from "../../contexts/AlertContext";

interface EventFormProps {
  event: MonitorEvent;
  saveButtonText?: string;
  onUpdate?: (event: MonitorEvent) => void;
  onDelete?: (event: MonitorEvent) => void;
}

const EventForm: React.FC<EventFormProps> = ({
  event,
  saveButtonText = "Save",
  onUpdate,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState(event.userNotes);

  const { getAccessTokenSilently } = useAuth0();
  const { showAlert } = useAlert();

  const handleAction = async (action: () => Promise<MonitorEvent>) => {
    setLoading(true);

    try {
      const resultEvent = await action();
      if (action === updateEvent) {
        onUpdate && onUpdate(resultEvent);
      } else if (action === deleteEvent) {
        onDelete && onDelete(resultEvent);
      }
      showAlert("SUCCESS", "Event has been updated");
    } catch (err) {
      showAlert("ERROR", "An error occurred!");
    }

    setLoading(false);
  };

  const updateEvent = async () => {
    const validation = {
      userNotes: notes,
      reviewed: true,
    };
    return EventAPI.update(
      event.siteId,
      event.eventId,
      validation,
      await getAccessTokenSilently(),
    );
  };

  const deleteEvent = async () => {
    return EventAPI.delete(
      event.siteId,
      event.eventId,
      await getAccessTokenSilently(),
    );
  };

  return (
    <>
      <Textarea
        value={notes}
        label="notes"
        className="!min-h-[110px]"
        onChange={(e) => setNotes(e.target.value)}
      />
      {loading ? (
        <div className="flex items-center justify-center">
          <Spinner className="h-6 w-6" />
        </div>
      ) : (
        <div className="flex flex-row-reverse gap-3 py-4">
          <Button
            className="w-2/3 bg-green-600"
            onClick={() => handleAction(updateEvent)}
          >
            {saveButtonText}
          </Button>

          <Button
            className="w-1/3 bg-inherit border border-gray-600 text-gray-600 dark:text-white"
            onClick={() => handleAction(deleteEvent)}
          >
            Remove
          </Button>
        </div>
      )}
    </>
  );
};

export default EventForm;
