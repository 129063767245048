import { Outlet } from "react-router-dom";
import Sidebar from "./components/ui/Sidebar/Sidebar";
import Content from "./components/ui/Content";

const Layout = () => {
  return (
    <div>
      <Sidebar />
      <Content>
        <Outlet />
      </Content>
    </div>
  );
};

export default Layout;
