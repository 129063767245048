import moment from "moment";
import { type MonitorEvent } from "../types";

/**
 * Format time to a string.
 * Eg. 13:50:55 Mon 27-02-23
 * @param {MonitorEvent} item event to get stringified date
 */
export const formatTime = (item: MonitorEvent): string => {
  return moment.utc(item.startTime).local().format("HH:mm:ss ddd DD-MM-YY");
};

/**
 * Get video duration from event
 * Eg. 3 (seconds)
 * @param event event to get duration from
 * @returns {number} duration second
 */
export const getSecondsFromEvent = (event: MonitorEvent): number => {
  return event
    ? (new Date(event.endTime).getTime() -
        new Date(event.startTime).getTime()) /
        1000
    : 0;
};

/**
 * Convert a string to title case
 * Eg. hello world -> Hello World
 * @param {string} str string to convert
 * @returns {string} title case string
 */
export const toTitleCase = (str: string): string => {
  return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};
