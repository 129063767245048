import { Select, Option, Spinner } from "@material-tailwind/react";

import Title from "../components/ui/Title";
import { useAllowedSites } from "../contexts/AllowedSitesContext";
import { useAlert } from "../contexts/AlertContext";

const Settings = () => {
  const { sites, loading: loadingSites } = useAllowedSites();
  const { showAlert } = useAlert();
  const { selectedSiteId, setSelectedSiteId } = useAllowedSites();

  const handleSiteIdSelectChange = (siteId: string | undefined) => {
    if (siteId) {
      setSelectedSiteId(siteId);
      showAlert("SUCCESS", "The selected Site ID has been updated");
    }
  };

  return (
    <>
      <Title>Settings</Title>

      {/* Site ID Selector */}
      {loadingSites ? (
        <Spinner className="w-5 h-5" />
      ) : (
        <>
          <h2 className="text-xl pb-2">Selected Site</h2>
          <Select
            className="dark:text-white dark:bg-zinc-400"
            size="lg"
            value={selectedSiteId}
            onChange={handleSiteIdSelectChange}
            label="Site ID"
          >
            {sites.map((site) => {
              return (
                <Option key={site.id} value={site.fingermark_id}>
                  {site.name}
                </Option>
              );
            })}
          </Select>
        </>
      )}
    </>
  );
};

export default Settings;
