import React, {
  useState,
  useEffect,
  useRef,
  SyntheticEvent,
  useMemo,
} from "react";
import ControlBar from "./ControlBar/ControlBar";
import { type MonitorEvent } from "../../../types";

import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from "@heroicons/react/24/solid";
import { Spinner } from "@material-tailwind/react";
import AnnotationViewer from "../Annotation/Annotation";

interface PlaybackProps {
  event: MonitorEvent;
  cols?: number;
}

const Playback: React.FC<PlaybackProps> = ({ event, cols = 2 }) => {
  const [videoElements, setVideoElements] = useState(
    new Array(event.videos.length).fill(null),
  );
  const [videoWrappers, setVideoWrappers] = useState<HTMLDivElement[]>([]);
  const [duration, setDuration] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoResolutions, setVideoResolutions] = useState<
    { width: number; height: number }[]
  >([]);
  const [enlargedVideoIndex, setEnlargedVideoIndex] = useState<number | null>(
    null,
  );

  const ready = useMemo(
    () => videoElements.every((ve) => ve !== null),
    [videoElements],
  );
  const initializedRefs = useRef(false);

  useEffect(() => {
    if (videoWrappers.length === event.videos.length) {
      initializedRefs.current = true;
    }
  }, [videoWrappers, event.videos.length]);

  function playAll() {
    setPlaying(true);
    videoElements.forEach((video) => video.play());
  }

  function pauseAll() {
    setPlaying(false);
    videoElements.forEach((video) => video.pause());
  }

  function handleLoadedMetadata(event: SyntheticEvent, i: number) {
    const video = event.target as HTMLVideoElement;

    setVideoElements((prevState) => {
      const updatedArray = [...prevState];
      updatedArray[i] = video;
      return updatedArray;
    });

    setVideoResolutions((prevResolutions) => {
      const updatedResolutions = [...prevResolutions];
      updatedResolutions[i] = {
        width: video.videoWidth,
        height: video.videoHeight,
      };
      return updatedResolutions;
    });

    setDuration((prevDuration) => Math.max(prevDuration, video.duration));
  }

  function handleVideoProgress(event: SyntheticEvent) {
    const video = event.target as HTMLVideoElement;
    setCurrentProgress((video.currentTime / video.duration) * 100);
    setCurrentTime(video.currentTime);
  }

  function handleVideoEnlarge(_: SyntheticEvent, i: number) {
    if (enlargedVideoIndex === i) {
      setEnlargedVideoIndex(null);
    } else {
      setEnlargedVideoIndex(i);
    }
  }

  const handleSeek = (time: number) => {
    if (!playing) {
      // when video has finished but user wants to seek back
      playAll();
    }
    videoElements.forEach((video) => (video.currentTime = time));
  };

  return (
    <>
      <div
        style={{
          gridTemplateColumns: `repeat(${
            enlargedVideoIndex !== null ? 1 : cols
          }, 1fr)`,
        }}
        className={`overflow-y-auto max-h-[50vh] grid gap-2 transition-all`}
      >
        {event.videos.map((video, i) => (
          <div
            className={`relative ${
              enlargedVideoIndex !== null && enlargedVideoIndex !== i
                ? "hidden"
                : ""
            }`}
            ref={(el) => {
              if (!initializedRefs.current && el) {
                setVideoWrappers((prev) => [...prev, el]);
              }
            }}
          >
            {enlargedVideoIndex === i ? (
              <ArrowsPointingInIcon
                className="absolute top-2 right-2 cursor-pointer z-10 h-5 w-5 text-white"
                onClick={(e) => handleVideoEnlarge(e, i)}
              />
            ) : (
              <ArrowsPointingOutIcon
                className="absolute top-2 right-2 cursor-pointer z-10 h-5 w-5 text-white"
                onClick={(e) => handleVideoEnlarge(e, i)}
              />
            )}

            <video
              className="rounded w-full h-full"
              controls={false}
              onLoadedMetadata={(e) => handleLoadedMetadata(e, i)}
              onTimeUpdate={(e) => {
                if (!isSeeking && i === 0) handleVideoProgress(e);
              }}
              onEnded={() => setPlaying(false)}
              onSeeking={() => setIsSeeking(true)}
              onSeeked={() => setIsSeeking(false)}
            >
              <source
                src={`${process.env.REACT_APP_PUBLIC_VIDEO_HOST}/${event.siteId}/raw/${video.bucketObjectId}`}
                type="video/mp4"
              />
            </video>

            {/* Overlay annotations */}
            {event.annotations && event.annotations[video.cameraId] && (
              <AnnotationViewer
                currentVideoTime={currentTime}
                annotations={event.annotations[video.cameraId]}
                resolution={videoResolutions[i]}
              />
            )}
          </div>
        ))}
      </div>
      <div className="p-3">
        {ready ? (
          <ControlBar
            playing={playing}
            currentProgress={currentProgress}
            duration={duration}
            onPlay={playAll}
            onPause={pauseAll}
            onSeek={handleSeek}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export default Playback;
