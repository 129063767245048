import { useAuth0 } from "@auth0/auth0-react";
import { Outlet } from "react-router-dom";
import Login from "../../views/Login";
import { useAllowedSites } from "../../contexts/AllowedSitesContext";
import AccessDenied from "../../views/AccessDenied";
import Loading from "../../views/Loading";

const ProtectedRoute = () => {
  const { isAuthenticated, isLoading: authLoading } = useAuth0();
  const { selectedSiteId, loading: sitesLoading } = useAllowedSites();

  // If not authenticated, show login.
  if (!isAuthenticated) {
    return <Login />;
  }

  // If the auth or sites are loading, show loading screen.
  if (authLoading || sitesLoading) {
    return <Loading />;
  }

  // If authenticated but no selected site, show access denied.
  if (!selectedSiteId) {
    return <AccessDenied />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
