// AlertContext.tsx
import { useState, createContext, useContext, ReactNode } from "react";
import { Alert } from "@material-tailwind/react";

type AlertContextType = {
  showAlert: (type: "SUCCESS" | "ERROR", message: string) => void;
};

const AlertContext = createContext<AlertContextType | undefined>(undefined);

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [message, setMessage] = useState<string | null>(null);
  const [type, setType] = useState<string | null>(null);

  const showAlert = (type: "SUCCESS" | "ERROR", message: string) => {
    setType(type);
    setMessage(message);

    setTimeout(() => {
      setMessage(null);
      setType(null);
    }, 3000); // Alert fades out after 3 seconds
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {message && (
        <Alert
          className={`fixed top-0 right-0 z-50 p-2 m-5 bg-red-600 text-white transition-all w-1/4 ${
            type === "SUCCESS" ? "bg-green-600" : "red"
          }`}
        >
          {message}
        </Alert>
      )}
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};
