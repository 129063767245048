import InfiniteScroll from "react-infinite-scroller";
import Title from "../components/ui/Title";
import { formatTime, toTitleCase } from "../lib/util";
import { Spinner } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import EventRowItem from "../components/ui/Event/RowItem";
import useFetchEvents from "../hooks/UseFetchEvents";
import DateRange from "../components/ui/DateRange";
import { useCallback } from "react";

const Archive = () => {
  const { events, loading, nextToken, fetchData, setDateRange } =
    useFetchEvents({
      reviewed: true,
    });

  const onDateChange = useCallback(
    (startDate: Date | undefined, endDate: Date | undefined) => {
      startDate && endDate
        ? // apply range filter
          setDateRange({
            start: startDate.toISOString(),
            end: endDate.toISOString(),
          })
        : // reset range filter
          setDateRange(undefined);
    },
    [setDateRange],
  );

  return (
    <>
      <Title>Archive</Title>

      <div className="py-2 w-full sm:w-1/2">
        <DateRange onDateChange={onDateChange} />
      </div>

      <div className="h-[calc(100vh-88px)] overflow-y-scroll relative">
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <Spinner className="h-6 w-6" />
          </div>
        ) : (
          <InfiniteScroll
            loadMore={() => fetchData({ reviewed: true, nextToken })}
            hasMore={!!nextToken}
            loader={<Spinner className="h-6 w-6 mb-10" />}
            useWindow={false}
          >
            {events.map((event) => {
              return (
                <Link to={`/view/${event.eventId}`} key={event.eventId}>
                  <EventRowItem>
                    <p className="p-2 overflow-hidden truncate w-52">
                      {formatTime(event)}
                    </p>
                    <p className="ml-auto px-3">
                      {toTitleCase(event.eventType)}
                    </p>
                  </EventRowItem>
                </Link>
              );
            })}
          </InfiniteScroll>
        )}
      </div>
    </>
  );
};

export default Archive;
