import { Button, Card, Typography } from "@material-tailwind/react";
import FingermarkLogo from "../assets/fingermark_logo.png";
import { useAuth0 } from "@auth0/auth0-react";

const AccessDenied = () => {
  const { logout } = useAuth0();

  return (
    <>
      <div className="text-white text-center bg-[#142249] flex items-center justify-center h-screen">
        <Card className="p-10 sm:w-[450px]">
          <img
            src={FingermarkLogo}
            className="m-auto h-12 w-12"
            alt="Fingermark Logo"
          />

          <div className="mb-2 mt-5 w-4/5 m-auto">
            <Typography variant="h5" color="blue-gray" className="mt-2 mb-3">
              Access Denied
            </Typography>

            <Typography>
              You do not have access to use this tool.
              <br />
              Please ask your administrator to grant you site permissions.
            </Typography>

            <Button
              size="sm"
              className="bg-zinc-400 m-auto w-3/8 mt-10"
              onClick={() => logout()}
            >
              Logout
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AccessDenied;
