import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Spinner } from "@material-tailwind/react";
import { type MonitorEvent } from "../types";

import Title from "../components/ui/Title";
import EventDetail from "../components/ui/Event/Details";
import EventForm from "../components/forms/UpdateEvent";
import EventRowItem from "../components/ui/Event/RowItem";
import { formatTime, toTitleCase } from "../lib/util";
import Playback from "../components/ui/MultiVideoPlayer/Playback";
import useFetchEvents from "../hooks/UseFetchEvents";
import ExportButton from "../components/ui/Event/ExportButton";

const Review = () => {
  const {
    events: fetchedEvents,
    loading,
    nextToken,
    fetchData,
  } = useFetchEvents({ reviewed: false });
  const [events, setEvents] = useState<MonitorEvent[]>(fetchedEvents);
  const [selected, setSelected] = useState(null as MonitorEvent | null);

  useEffect(() => {
    setEvents(fetchedEvents);
    setSelected(fetchedEvents?.length ? fetchedEvents[0] : null);
  }, [fetchedEvents]);

  const removeEvent = (event: MonitorEvent) => {
    const updatedEvents = events.filter(
      (e: MonitorEvent) => e.eventId !== event.eventId,
    );
    setEvents(updatedEvents);

    // If the removed event was the selected one, select a new one or set to null if no events are left
    if (selected?.eventId === event.eventId) {
      setSelected(updatedEvents.length ? updatedEvents[0] : null);
    }
  };

  return (
    <>
      <Title>Review</Title>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner className="h-6 w-6" />
        </div>
      ) : (
        <div className="lg:grid lg:grid-cols-10 flex-1 space-x-2">
          {/* Item Listings*/}
          <div className="lg:col-span-3 h-32 lg:h-[calc(100vh-88px)] overflow-y-scroll relative rounded">
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={() => fetchData({ reviewed: false, nextToken })}
              hasMore={!!nextToken && !loading}
              loader={<Spinner className="h-6 w-6 mb-10" />}
              useWindow={false}
            >
              {events.map((event) => {
                return (
                  <EventRowItem
                    key={event.eventId}
                    onClick={() => setSelected(event)}
                    selected={selected?.eventId === event.eventId}
                  >
                    <p className="p-2 overflow-hidden truncate w-52">
                      {formatTime(event)}
                    </p>
                    <p className="ml-auto px-3">
                      {toTitleCase(event.eventType)}
                    </p>
                  </EventRowItem>
                );
              })}
            </InfiniteScroll>
          </div>

          {/* View Pane */}
          <div className="lg:col-span-7">
            {!selected ? (
              <div className="flex flex-col items-center justify-center h-full">
                <h1 className="text-2xl my-1 uppercase">
                  Select an event to review
                </h1>
              </div>
            ) : (
              <div key={`selected-${selected.eventId}`}>
                <div className="h-2/3">
                  <Playback event={selected} />
                </div>
                <ExportButton event={selected} />
                <div className="h-1/3 space-y-5 md:space-y-0 md:grid md:grid-cols-2 gap-3 my-2 space-y-3">
                  <EventDetail event={selected} />
                  <div>
                    <EventForm
                      event={selected}
                      saveButtonText={"Archive"}
                      onDelete={removeEvent}
                      onUpdate={removeEvent}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Review;
