import { Routes, Route, Navigate } from "react-router-dom";
import "./index.css";

import Layout from "./Layout";
import Review from "./views/Review";
import ProtectedRoute from "./components/ui/ProtectedRoute";
import Archive from "./views/Archive";
import ViewEvent from "./views/ViewEvent";
import Settings from "./views/Settings";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route element={<Layout />}>
          <Route index element={<Navigate to="/review" />} />
          <Route path="review" element={<Review />} />
          <Route path="archive" element={<Archive />} />
          <Route path="view">
            <Route path=":eventId" element={<ViewEvent />} />
          </Route>
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

export default App;
