import axios, { AxiosRequestConfig } from "axios";
import { type MonitorEvent, type getStores } from "../types";

const baseURL = process.env.REACT_APP_CATCH_API_URL;
if (!baseURL) {
  throw new Error("REACT_APP_CATCH_API_URL environment variable is required");
}

export const api = axios.create({ baseURL });

async function requestAPI<T>(
  method: "GET" | "DELETE" | "PUT",
  url: string,
  accessToken: string,
  data?: any,
  axiosOptions?: AxiosRequestConfig,
): Promise<T> {
  const res = await api.request({
    method,
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data,
    ...axiosOptions,
  });

  if (res.status !== 200) {
    console.error(`Failed to ${method} ${url}`);
    throw new Error(`${res.status} ${res.statusText}`);
  }

  return res.data as T;
}

const EventAPI = {
  /**
   * Get a single event
   */
  get: (siteId: string, eventId: string, accessToken: string) =>
    requestAPI<MonitorEvent>(
      "GET",
      `/events/${siteId}/${eventId}`,
      accessToken,
    ),
  /**
   * Get a list of events
   */
  getAll: async (
    siteId: string,
    accessToken: string,
    options?: {
      limit?: number;
      nextToken?: string;
      reviewed?: boolean;
      start?: string;
      end?: string;
    },
  ) => {
    const queryParams = [
      `limit=${options?.limit || 25}`,
      options?.nextToken
        ? `nextToken=${encodeURIComponent(options?.nextToken)}`
        : undefined,
      options?.start && options?.end
        ? `start=${options.start}&end=${options.end}`
        : undefined,
      options?.reviewed !== undefined
        ? `reviewed=${options?.reviewed}`
        : undefined,
    ]
      .filter(Boolean)
      .join("&");

    return requestAPI<{ items: MonitorEvent[]; nextToken: string }>(
      "GET",
      `/events/${siteId}?${queryParams}`,
      accessToken,
    );
  },
  /**
   * Delete an event
   */
  delete: (siteId: string, eventId: string, accessToken: string) =>
    requestAPI<MonitorEvent>(
      "DELETE",
      `/events/${siteId}/${eventId}`,
      accessToken,
    ),
  /**
   * Update an event
   */
  update: (
    siteId: string,
    eventId: string,
    validation: {
      userNotes?: string | undefined;
      reviewed: boolean;
    },
    accessToken: string,
  ) =>
    requestAPI<MonitorEvent>(
      "PUT",
      `/events/${siteId}/${eventId}`,
      accessToken,
      validation,
    ),
  /**
   * Get user's allowed sites
   */
  allowedSites: (accessToken: string) =>
    requestAPI<getStores[]>("GET", `/my-sites`, accessToken),
  /**
   * Grant access for user to view site data
   */
  getContentAccess: (siteId: string, accessToken: string) =>
    requestAPI<{ sites: string[] }>(
      "GET",
      `/get-content-access/${siteId}`,
      accessToken,
      undefined,
      {
        withCredentials: true,
      },
    ),
};

export default EventAPI;
