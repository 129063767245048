import { Button, Input } from "@material-tailwind/react";
import { useEffect, useState } from "react";

interface DateRangeProps {
  onDateChange: (
    startDate: Date | undefined,
    endDate: Date | undefined,
  ) => void;
}

const DateRange: React.FC<DateRangeProps> = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (startDate && endDate && !error) {
      onDateChange(new Date(startDate), new Date(endDate));
    }
  }, [startDate, endDate, error, onDateChange]);

  const handleStartDateChange = (value: string) => {
    if (endDate && new Date(value) > new Date(endDate)) {
      setError("Start date cannot be after the end date");
      return;
    }

    setError("");
    setStartDate(value);
  };

  const handleEndDateChange = (value: string) => {
    if (startDate && new Date(value) < new Date(startDate)) {
      setError("End date cannot be before the start date");
      return;
    }

    setError("");
    setEndDate(value);
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    onDateChange(undefined, undefined);
  };

  return (
    <div className="flex flex-col space-y-1">
      {error && <p className="text-red-500 mt-2 text-sm">{error}</p>}
      <div className="flex flex-col gap-y-2 sm:flex-row sm:space-x-1">
        <Input
          type="date"
          label="Start Date"
          value={startDate}
          onChange={({ target: { value } }) => handleStartDateChange(value)}
        />
        <Input
          type="date"
          label="End Date"
          value={endDate}
          onChange={({ target: { value } }) => handleEndDateChange(value)}
        />
        <Button
          variant="outlined"
          className="min-w-[200px] sm:min-w-[90px] py-0 bg-inherit border border-blue-gray-200 text-blue-gray-500"
          onClick={() => handleReset()}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default DateRange;
