import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  List,
  ListItem,
  ListItemPrefix,
  Popover,
  PopoverHandler,
  PopoverContent,
  Typography,
} from "@material-tailwind/react";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/solid";
import { ReactNode } from "react";

interface LogoutPopoverProps {
  children: ReactNode;
}

const LogoutPopover: React.FC<LogoutPopoverProps> = ({ children }) => {
  const { user, logout } = useAuth0();

  const signOut = () => {
    const rootURL = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ":" + window.location.port : ""
    }`;
    logout({ logoutParams: { returnTo: rootURL } });
  };

  return (
    <Popover>
      <PopoverHandler>{children}</PopoverHandler>
      <PopoverContent className="dark:bg-zinc-400 dark:text-white z-40">
        <div className="mb-4 flex items-center gap-4 border-b pb-4">
          <Avatar src={user?.picture} alt="avatar" size="sm" />
          <div>
            <Typography variant="h6">{user?.nickname}</Typography>
            <Typography
              variant="small"
              className="font-normal truncate w-3/4 text-gray-500 dark:text-white"
            >
              {user?.email}
            </Typography>
          </div>
        </div>
        <List className="p-0">
          <ListItem
            className="bg-gray-200 dark:bg-gray-800 dark:!text-white hover:!bg-green-600 hover:!text-white"
            onClick={() => signOut()}
          >
            <ListItemPrefix>
              <ArrowLeftOnRectangleIcon className="w-5 h-5 mr-2" />
            </ListItemPrefix>
            Logout
          </ListItem>
        </List>
      </PopoverContent>
    </Popover>
  );
};

export default LogoutPopover;
