import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAllowedSites as useAllowedSitesHook } from "../hooks/UseAllowedSites";
import { useSiteContentAccess } from "../hooks/UseSiteContentAccess";
import { type getStores } from "../types";

interface Props {
  children: ReactNode;
}

export const AllowedSitesProvider: React.FC<Props> = ({ children }) => {
  const { sites, loadingSites } = useAllowedSitesHook();
  const [selectedSiteId, setSelectedSiteId] = useState<string | null>(
    localStorage.getItem("selectedSiteId"),
  );
  const { loadingAccess } = useSiteContentAccess(selectedSiteId);

  /**
   * Update users selected site to default or revoking if not in allowed sites
   */
  useEffect(() => {
    // Update if no selected site, or the previously selected is no longer permitted
    const siteIds = sites.map((site) => site.fingermark_id);
    if (!selectedSiteId || !siteIds.includes(selectedSiteId)) {
      if (sites.length > 0) {
        // default to first site
        updateSelectedSite(siteIds[0]);
      } else {
        revokeSelectedSite();
      }
    }
  }, [sites, selectedSiteId]);

  /**
   * Update selected site value and cookie
   * @param site siteId to set
   */
  const updateSelectedSite = (site: string) => {
    setSelectedSiteId(site);
    localStorage.setItem("selectedSiteId", site);
  };

  /**
   * Nullify selected site value and delete cookie
   */
  const revokeSelectedSite = () => {
    setSelectedSiteId(null);
    localStorage.removeItem("selectedSiteId");
  };

  return (
    <AllowedSitesContext.Provider
      value={{
        sites,
        selectedSiteId: selectedSiteId as string,
        setSelectedSiteId,
        loading: loadingAccess || loadingSites,
      }}
    >
      {children}
    </AllowedSitesContext.Provider>
  );
};

interface SitesContextType {
  sites: getStores[];
  selectedSiteId: string;
  loading: boolean;
  setSelectedSiteId: (id: string) => void;
}

const AllowedSitesContext = createContext<SitesContextType | undefined>(
  undefined,
);

export const useAllowedSites = (): SitesContextType => {
  const context = useContext(AllowedSitesContext);
  if (!context) {
    throw new Error(
      "useAllowedSites must be used within a AllowedSitesProvider",
    );
  }
  return context;
};
