import { useAuth0 } from "@auth0/auth0-react";
import LogoutPopover from "./LogoutPopover";
import { Avatar } from "@material-tailwind/react";

const Title = (props: any) => {
  const { user } = useAuth0();

  return (
    <>
      <div className="w-full h-16 md:h-28 flex items-center bg-white dark:bg-gray-900">
        <p className="text-xl md:text-5xl uppercase font-semibold text-black dark:text-white">
          {props.children}
        </p>

        <LogoutPopover>
          <div className="ml-auto inline-block relative flex jusify-center items-center space-x-3">
            <p className="truncate uppercase font-bold text-black dark:text-white hidden md:flex">
              {user?.nickname}
            </p>
            <Avatar src={user?.picture} alt="avatar" size="sm" />
          </div>
        </LogoutPopover>
      </div>
    </>
  );
};

export default Title;
