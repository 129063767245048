import React, { useState } from "react"; // 1. Import useState from React
import { ArrowDownOnSquareIcon } from "@heroicons/react/24/solid";
import { Button, Spinner, Tooltip } from "@material-tailwind/react";
import { exportEvent } from "../../../services/Exporter";
import { MonitorEvent } from "../../../types";

interface ExportButtonProps {
  event: MonitorEvent;
}

const EventButton: React.FC<ExportButtonProps> = ({ event }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleExport = async () => {
    setIsLoading(true);

    try {
      await exportEvent(event);
    } catch (error) {
      console.error("Error exporting event:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Tooltip content="Export event as ZIP">
      <Button
        size="sm"
        className="bg-inherit border border-gray-600 text-gray-600 dark:text-white my-2"
        onClick={handleExport}
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner className="h-5 w-5" />
        ) : (
          <ArrowDownOnSquareIcon className="h-5 w-5" />
        )}
      </Button>
    </Tooltip>
  );
};

export default EventButton;
