import React, { useState, useCallback, useEffect } from "react";
import "./ControlBar.css";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";

interface ControlBarProps {
  playing: boolean;
  currentProgress: number;
  duration: number;
  onPlay?: () => void;
  onPause?: () => void;
  onSeek?: (time: number) => void;
}

const ControlBar: React.FC<ControlBarProps> = ({
  playing = false,
  currentProgress = 0,
  duration = 0,
  onPlay,
  onPause,
  onSeek,
}) => {
  const [sliderValue, setSliderValue] = useState(currentProgress);
  const [isDragging, setIsDragging] = useState(false);

  const handleTogglePlay = useCallback(() => {
    if (playing) {
      onPause && onPause();
    } else {
      onPlay && onPlay();
    }
  }, [playing, onPause, onPlay]);

  const handleSeek = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(event.target.value);
      const time = (value / 100) * duration;
      setSliderValue(value);
      onSeek && onSeek(time);
    },
    [duration, onSeek],
  );

  useEffect(() => {
    if (currentProgress !== sliderValue && !isDragging) {
      setSliderValue(currentProgress);
    }
  }, [currentProgress, sliderValue, isDragging]);

  return (
    <div className="flex items-center justify-center">
      <button onClick={handleTogglePlay} className="play-pause-btn">
        {playing ? (
          <PauseIcon className="h-5 w-5" />
        ) : (
          <PlayIcon className="h-5 w-5" />
        )}
      </button>
      <input
        className="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-300"
        type="range"
        min="0"
        max="100"
        value={sliderValue}
        onChange={handleSeek}
        onMouseDown={() => setIsDragging(true)}
        onMouseUp={() => setIsDragging(false)}
      />
    </div>
  );
};

export default ControlBar;
