import React, { useState, useEffect } from "react";
import polyline from "@mapbox/polyline";

const colors = ["orange", "green", "red", "blue", "purple", "pink", "cyan"];

type Annotation = {
  start: number;
  end: number;
  label: string;
  polyline: string;
};

interface AnnotationViewerProps {
  currentVideoTime: number;
  annotations: Annotation[];
  resolution: {
    width: number;
    height: number;
  };
}

const AnnotationViewer: React.FC<AnnotationViewerProps> = ({
  currentVideoTime,
  annotations,
  resolution,
}) => {
  const [visibleAnnotations, setVisibleAnnotations] = useState(
    [] as Annotation[],
  );

  // Update visable annotations
  useEffect(() => {
    if (currentVideoTime >= 0) {
      setVisibleAnnotations(annotations.filter(shouldDisplayAnnotation));
    }
  }, [currentVideoTime]);

  // Check if annotation should be rendered based on time
  const shouldDisplayAnnotation = (annotation: {
    start: number;
    end: number;
  }) => {
    return (
      currentVideoTime >= annotation.start && currentVideoTime <= annotation.end
    );
  };

  // Get position of top left for text placement
  const topLeftPosition = (coords: [number, number][]) => {
    if (coords.length === 0) return [0, 0];

    let minX = coords[0][0];
    let minY = coords[0][1];

    coords.forEach((coord) => {
      if (coord[0] < minX) minX = coord[0];
      if (coord[1] < minY) minY = coord[1];
    });

    return [minX, minY];
  };

  return (
    <svg
      className="absolute top-0 left-0 w-full h-full pointer-events-none"
      viewBox={`0 0 ${resolution?.width || 1920} ${resolution?.height || 1080}`}
    >
      {visibleAnnotations.map((annotation, index) => {
        const decodedPolyline = polyline.decode(annotation.polyline);
        const [x, y] = topLeftPosition(decodedPolyline);
        return (
          <React.Fragment key={`${annotation.label}-${annotation.start}`}>
            <polygon
              points={decodedPolyline.join(" ")}
              fill="none"
              stroke={colors[index % colors.length]}
              strokeWidth="10px"
            />
            <text
              x={x}
              y={y}
              dx="0.5em"
              dy="1em"
              fill={colors[index % colors.length]}
              textAnchor="start"
              fontSize="50"
            >
              {annotation.label}
            </text>
          </React.Fragment>
        );
      })}
    </svg>
  );
};

export default AnnotationViewer;
