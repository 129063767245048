import { useAuth0 } from "@auth0/auth0-react";
import FingermarkLogo from "../assets/fingermark_logo.png";
import { Button, Card, Typography } from "@material-tailwind/react";

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <div className="text-white text-center bg-[#142249] flex items-center justify-center h-screen">
        <Card className="p-10 sm:w-[450px]">
          <img
            src={FingermarkLogo}
            className="m-auto h-12 w-12"
            alt="Fingermark Logo"
          />

          <Typography variant="h2" color="blue-gray" className="m-5">
            Monitor
          </Typography>

          <Button
            size="sm"
            className="bg-zinc-400 m-auto mt-2 w-2/3"
            onClick={() => loginWithRedirect()}
          >
            Login
          </Button>
        </Card>
      </div>
    </>
  );
};

export default Login;
