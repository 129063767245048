import { useEffect, useState, useRef, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import EventAPI from "../services/EventAPI";
import { useAllowedSites } from "../contexts/AllowedSitesContext";
import { MonitorEvent } from "../types";

const useFetchEvents = (initialOptions: {
  reviewed?: boolean;
  nextToken?: string;
  start?: string;
  end?: string;
}) => {
  const [events, setEvents] = useState<MonitorEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [nextToken, setNextToken] = useState<string | undefined>(
    initialOptions.nextToken,
  );
  const [dateRange, setDateRange] = useState<
    { start: string; end: string } | undefined
  >();

  const currentNextToken = useRef(nextToken);
  const { getAccessTokenSilently } = useAuth0();
  const { loading: sitesLoading, selectedSiteId } = useAllowedSites();
  const fetchOptions = useRef(initialOptions);

  const fetchData = useCallback(
    async (options = initialOptions) => {
      if (!selectedSiteId || sitesLoading) return;

      try {
        const token = await getAccessTokenSilently();
        const eventData = await EventAPI.getAll(selectedSiteId, token, options);

        setEvents((prevEvents) => [...prevEvents, ...eventData.items]);

        setNextToken(eventData.nextToken);
        currentNextToken.current = eventData.nextToken;
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    },
    [selectedSiteId, sitesLoading],
  );

  useEffect(() => {
    if (dateRange) {
      fetchOptions.current = { ...fetchOptions.current, ...dateRange };
    }

    fetchData(fetchOptions.current);

    return () => {
      setEvents([]);
      setNextToken(undefined);
      setLoading(true);
      setError(null);
    };
  }, [sitesLoading, selectedSiteId, dateRange, fetchData]);

  return {
    events,
    loading,
    error,
    nextToken: currentNextToken.current,
    fetchData,
    setDateRange,
  };
};

export default useFetchEvents;
