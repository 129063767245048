import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "@material-tailwind/react";
import {
  ArchiveBoxIcon,
  ViewfinderCircleIcon,
  Cog6ToothIcon,
  LightBulbIcon,
  MoonIcon,
} from "@heroicons/react/24/solid";

import SidebarItem from "./SidebarItem";

import EyecueLogo from "../../../assets/eyecue_logo.png";
import FingermarkLogo from "../../../assets/fingermark_logo_with_text.png";

const Sidebar = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme) {
      return currentTheme === "dark";
    }
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  });

  useEffect(() => {
    if (isDarkMode) {
      localStorage.setItem("theme", "dark");
      document.documentElement.classList.add("dark");
    } else {
      localStorage.setItem("theme", "light");
      document.documentElement.classList.remove("dark");
    }
  }, [isDarkMode]);

  return (
    <Card className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full md:translate-x-0 bg-green-600">
      <div className="p-4 h-28 bg-zinc-500 flex items-center justify-center">
        <Link to="/">
          <img
            src={EyecueLogo}
            className="w-[210px] h-[50px]"
            alt="Eyecue Logo"
          />
        </Link>
      </div>
      <div>
        <SidebarItem link="/review">
          <ViewfinderCircleIcon className="h-6 w-6 mr-4" /> Review
        </SidebarItem>

        <SidebarItem link="/archive">
          <ArchiveBoxIcon className="h-6 w-6 mr-4" /> Archive
        </SidebarItem>

        <SidebarItem link="/settings">
          <Cog6ToothIcon className="h-6 w-6 mr-4" /> Settings
        </SidebarItem>
      </div>

      <div className="mt-auto bg-gray-900 flex items-center justify-center h-12 px-2">
        <img src={FingermarkLogo} alt="Fingermark Logo" />
        <div className="ml-auto" onClick={() => setIsDarkMode(!isDarkMode)}>
          {isDarkMode ? (
            <LightBulbIcon className="h-6 w-6 text-white" />
          ) : (
            <MoonIcon className="h-6 w-6 text-white" />
          )}
        </div>
      </div>
    </Card>
  );
};

export default Sidebar;
