import JSZip from "jszip";
import { MonitorEvent } from "../types";
import saveAs from "file-saver";

export const exportEvent = async (event: MonitorEvent) => {
  var zip = new JSZip();

  // Add event data to metadata.json
  zip.file("metadata.json", createMetadata(event));

  // Download videos and add them to zip
  await Promise.all(
    event.videos.map(async (video) => {
      const videoUrl = `${process.env.REACT_APP_PUBLIC_VIDEO_HOST}/${event.siteId}/raw/${video.bucketObjectId}`;
      const videoContent = await getVideoBlob(videoUrl);
      zip.file(video.cameraId + ".mp4", videoContent);
    }),
  );

  // Generate zip and allow user to download it
  zip.generateAsync({ type: "blob" }).then((contents) => {
    const name = `${event.eventId}_${Date.now()}.zip`;
    saveAs(contents, name);
  });
};

// Helper function to fetch and add video to the zip
const getVideoBlob = async (videoUrl: string) => {
  const response = await fetch(videoUrl, { credentials: "include" });
  if (!response.ok) {
    throw new Error(`Failed to fetch video from ${videoUrl}`);
  }
  return await response.blob();
};

// Format event into metadata with basic event values
const createMetadata = (event: MonitorEvent, indent = 4) => {
  return JSON.stringify(
    {
      eventId: event.eventId,
      siteId: event.siteId,
      startTime: event.startTime,
      endTime: event.endTime,
    },
    null,
    indent,
  );
};
