import { useAuth0 } from "@auth0/auth0-react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAllowedSites } from "../contexts/AllowedSitesContext";
import { type MonitorEvent } from "../types";

import { Button, Spinner } from "@material-tailwind/react";
import EventForm from "../components/forms/UpdateEvent";
import EventDetail from "../components/ui/Event/Details";
import Playback from "../components/ui/MultiVideoPlayer/Playback";
import Title from "../components/ui/Title";
import EventAPI from "../services/EventAPI";
import ExportButton from "../components/ui/Event/ExportButton";

const ViewEvent = () => {
  let { eventId } = useParams();
  const [event, setEvent] = useState<MonitorEvent | null>();
  const [loading, setLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const { loading: sitesLoading, selectedSiteId } = useAllowedSites();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedSiteId || sitesLoading) return;

      const token = await getAccessTokenSilently();
      EventAPI.get(selectedSiteId, eventId as string, token).then((res) => {
        setEvent(res);
      });
      setLoading(false);
    };

    fetchData();
  }, [eventId, getAccessTokenSilently, selectedSiteId, sitesLoading]);

  return (
    <>
      <Title>Inspect Event</Title>
      {event && !loading ? (
        <div className="px-4">
          <Button
            size="sm"
            className="bg-inherit border border-gray-600 text-gray-600 dark:text-white my-2"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon className="w-4 h-4" />
          </Button>

          <Playback event={event} />

          <ExportButton event={event} />

          <div className="space-y-5 md:space-y-0 sm:grid sm:grid-cols-2 gap-3 my-2">
            <div className="space-y-3">
              <EventDetail event={event} />
            </div>
            <div className="space-y-3">
              <EventForm event={event} onDelete={() => navigate("/archive")} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-full">
          <Spinner className="h-6 w-6" />
        </div>
      )}
    </>
  );
};

export default ViewEvent;
