import { ReactNode } from "react";

interface EventRowItemProps {
  children: ReactNode;
  selected?: boolean;
  onClick?: () => void;
}

const EventRowItem: React.FC<EventRowItemProps> = ({
  children,
  selected,
  onClick,
}) => {
  const color = selected
    ? "bg-green-600 text-white"
    : "bg-gray-200 text-gray-700 dark:bg-zinc-400 dark:text-white";

  return (
    <div
      className={`${color} w-full flex flex-nowrap rounded px-2 mb-1 h-15 lg:h-[50px] items-center hover:cursor-pointer hover:!bg-green-700 hover:text-white`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default EventRowItem;
