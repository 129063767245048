import { ReactNode, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation

interface SidebarItemProps {
  link: string;
  children: ReactNode;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ link, children }) => {
  const [selected, setSelected] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setSelected(location.pathname === link);
  }, [location.pathname, link]);

  const color = selected
    ? "bg-white text-green-600 hover:text-white"
    : "bg-green-600 text-white";

  return (
    <Link to={link}>
      <div
        className={`${color} flex items-center px-2 py-3 w-full font-semibold uppercase hover:bg-green-700`}
      >
        {children}
      </div>
    </Link>
  );
};

export default SidebarItem;
